var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"header-census"},[_c('h2',{},[_vm._v("统计")]),_c('el-row',{staticClass:"card",attrs:{"gutter":40}},[_c('el-col',{attrs:{"span":6}},[_c('el-card',{staticClass:"box-card",attrs:{"shadow":"always"}},[_c('p',{staticClass:"title"},[_vm._v("用户总人数")]),_c('p',{staticClass:"count"},[_vm._v(_vm._s(_vm.total))])])],1),_c('el-col',{attrs:{"span":6}},[_c('el-card',{staticClass:"box-card",attrs:{"shadow":"always"}},[_c('p',{staticClass:"title"},[_vm._v("账号冻结人数")]),_c('p',{staticClass:"count"},[_vm._v(_vm._s(_vm.freezeTotal))])])],1)],1)],1),_c('el-form',{staticClass:"form",attrs:{"inline":true,"model":_vm.formInline}},[_c('el-form-item',{attrs:{"label":"社区选择:"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":"","placeholder":"请选择小区"},model:{value:(_vm.formInline.addressId),callback:function ($$v) {_vm.$set(_vm.formInline, "addressId", $$v)},expression:"formInline.addressId"}},_vm._l((_vm.communityData),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"状态:"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"状态"},model:{value:(_vm.formInline.status),callback:function ($$v) {_vm.$set(_vm.formInline, "status", $$v)},expression:"formInline.status"}},[_c('el-option',{attrs:{"label":"正常","value":0}}),_c('el-option',{attrs:{"label":"冻结","value":1}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"info"},on:{"click":_vm.onReSet}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSearch}},[_vm._v("查询")])],1)],1),_c('data-table',{attrs:{"loading":_vm.loading,"tableData":_vm.tableData,"column":_vm.column,"total":_vm.total},on:{"changePagination":_vm.changePagination},scopedSlots:_vm._u([{key:"nickName",fn:function(ref){
var id = ref.id;
var nickName = ref.nickName;
return [_c('span',{staticStyle:{"color":"blue","cursor":"pointer"},on:{"click":function($event){return _vm.toMore(id)}}},[_vm._v(_vm._s(nickName))])]}},{key:"attentionAddress",fn:function(ref){
var attentionAddress = ref.attentionAddress;
return [_c('el-tooltip',{attrs:{"effect":"dark","content":attentionAddress,"placement":"top-start"}},[_c('span',{staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(_vm.ellipsisStr(attentionAddress)))])])]}},{key:"status",fn:function(ref){
var status = ref.status;
return [(status == 0)?_c('el-tag',[_vm._v("正常")]):(status == 1)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("冻结")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }