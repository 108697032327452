






















































import { Vue, Component } from 'vue-property-decorator'
import { ColumnConfig } from '@/components/table/types'
@Component
export default class Demo extends Vue {
  loading = false;
  total = 0;
  freezeTotal = 0;
  communityData = [];
  current = 1;
  formInline = {
    addressId: '',
    status: ''
  };

  tableData = [];

  column: Array<ColumnConfig> = [
    {
      label: '用户名',
      code: 'nickName',
      scopedSlots: 'nickName'
    },
    {
      label: '手机号',
      code: 'phone'
    },
    {
      label: '所属小区',
      code: 'address'
    },
    {
      label: '注册日期',
      code: 'insertAt'
    },
    {
      label: '关注小区',
      code: 'attentionAddress',
      scopedSlots: 'attentionAddress'
    },
    {
      label: '最近活跃日期',
      code: 'updateAt'
    },
    {
      label: '状态',
      code: 'status',
      scopedSlots: 'status'
    },
    {
      label: '操作',
      key: 'action',
      options: [
        {
          title: '冻结',
          click: (row: any) => {
            this.updateCommunity(row.id, 1)
          }
        },
        {
          title: '解冻',
          click: (row: any) => {
            this.updateCommunity(row.id, 0)
          }
        },
        {
          title: '删除',
          click: (row: any) => {
            this.updateCommunity(row.id, -1)
          }
        }
      ]
    }
  ];

  ellipsisStr<T> (str: T, limit = 15): T | string {
    if (typeof str === 'string' && str && str.length > limit) {
      return str.substring(0, limit) + '...'
    } else {
      return str
    }
  }

  toMore (id: number) {
    // '/accountinfo',query:{id:id}
    this.$router.push({ path: '/accountinfo', query: { id: String(id) } })
  }

  onSearch (): void {
    this.fetch()
  }

  onReSet () {
    this.formInline = {
      addressId: '',
      status: ''
    }
    this.fetch()
  }

  async updateCommunity (id: number, type: number) {
    const res = await this.$Post(this.$Api.updateUser, {
      id,
      type
    })
    this.fetch()
  }

  async fetch () {
    this.loading = true
    // const r = await this.$Get(this.$Api.userListPage, {
    //   pageSize: 10,
    //   current: 1,
    //   ...this.formInline
    // })
    const [r1, r2] = await Promise.all([
      this.$Get(this.$Api.userListPage, {
        pageSize: 10,
        current: this.current,
        ...this.formInline
      }),
      this.$Get(this.$Api.getUserTotal)
    ])
    this.freezeTotal = r2.data.freezeTotal
    this.tableData = r1.data.records
    this.total = r1.data.total
    this.loading = false
  }

  async fetchCommunityData () {
    const res = await this.$Get(this.$Api.getCommunityList)
    this.communityData = res.data
  }

  changePagination (current: number) {
    this.current = current
    this.fetch()
  }

  mounted () {
    this.fetch()
    this.fetchCommunityData()
  }
}
